const ehelseUrl = 'https://www.ehelse.no'
const ehelseKodeverk = ehelseUrl + '/kodeverk-og-terminologi'
const helsedirUrl = 'https://www.helsedirektoratet.no'
const helsedirKodeverk =
	helsedirUrl + '/digitalisering-og-e-helse/helsefaglige-kodeverk'

const aboutIcd = '/icd'
const aboutNkpk = '/nkpk'
const aboutPhbu = '/phbu'
const aboutIcpc = '/icpc'
const aboutKis = '/kodeverk-i-e-helsestandarder'
const aboutNorpat = '/norpat'
const aboutLab = '/nlk'

const aboutSnomedct = '/SNOMED-CT'

const reportErrorLab = 'lab@helsedir.no'
const reportErrorEmail = 'kodehjelp@helsedir.no'
const reportErrorAdm = 'kodehjelp@helsedir.no'
const koteUrl = 'https://kote.helsedirektoratet.no'
const terminologiString = '/terminologi'
const kliniskeString = '/kliniske'

const snomedctBrowser = 'https://snowstorm.terminologi.helsedirektoratet.no'

const blueBookIcdAttachment =
	'/standarder/icd-10-psykiske-lidelser-og-atferdsforstyrrelse-kliniske-beskrivelser-og-diagnostiske-retningslinjer-blaboka/ICD-10%20Psykiske%20lidelser%20og%20atferdsforstyrrelse%20kliniske%20beskrivelser%20og%20diagnostiske%20retningslinjer.pdf/_/attachment/inline/57e72124-345b-47a8-aacc-2d572c8f9f78:6875a2309af7447a26d9731a6e7ff2cba814c14e/ICD-10%20Psykiske%20lidelser%20og%20atferdsforstyrrelse%20kliniske%20beskrivelser%20og%20diagnostiske%20retningslinjer.pdf'
const codingGuideIcdAndNkpk2025Attachment =
	'https://www.helsedirektoratet.no/veiledere/kodeveiledning-2025-regler-og-veiledning-for-klinisk-koding-i-spesialisthelsetjenesten'
const codingGuidePhbuAttachment =
	'https://www.helsedirektoratet.no/veiledere/multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-phbu-veiledning-til-koding'

/* Temporary download files until the api works well with clinical codesystems */
const downloadExcelIcdTemp =
	'/ICD-10-og-ICD-11/_/attachment/inline/ab0389f0-6aa4-4a96-a660-c3aae7003e9b:2c2a004278565f532cd77dbc729eb90ca5e876ff/ICD-10%202025%20kodeliste.xlsx'
const downloadExcelIcpcTemp =
	'/ICPC-2/_/attachment/inline/eb98d769-65ec-4f72-bb6d-b5bf8be7d97f:6af72fa60887d1aa9b83b5c8d4dd012dc4f110e0/Fil%201%202025%20-%20ICPC-2%20koderegister%20med%20utvidet%20termsett%20(flere%20linjer%20per%20kode).xlsx'
const downloadExcelNkpkTemp =
	'/Norsk-klinisk-prosedyrekodeverk-(NKPK)/_/attachment/inline/98e26142-c855-4697-ad03-a4632288498e:4df94906fa6ebeaec506b8ff97b8bb30abd747fd/Alle%20prosedyrekoder%20NKPK%202025%20-%20Oppdatert%202.%20desember%202024.xlsx'
const downloadExcelPhbuTemp =
	'/Multiaksial-klassifikasjon-i-psykisk-helsevern-for-barn-og-unge-(PHBU)/_/attachment/inline/2302d2d3-6816-4a6b-a4c4-9e39e8e0a0f1:6a0ad03ae6308d4e20e9f006574e783ba4111ec3/Kodeliste%20PHBU%202025.xlsx'
const downloadExcelNorpatTemp =
	'/Norsk-patologikodeverk/_/attachment/inline/dfdd922a-ef56-4b17-a74a-811d34dbd359:66b24d1583c1e9f13b60b8de0433bda8289ed832/Norsk%20patologikodeverk%20(NORPAT)%202025.xlsx'
const downloadCsvNlkTemp =
	'/Laboratoriekodeverk/_/attachment/inline/18b2c07f-2662-4762-9561-0465530cba19:c3ff952e1c91917e25235aff9448cdbfdd31b82c/Norsk%20laboratoriekodeverk%207280.69.csv'

const ehelseFeedbackForm =
	'https://forms.office.com/Pages/ResponsePage.aspx?id=XL2haw911kqrow-VWFvCH_vvJjJGPaVFqLMA_mOzyM5URE81V1oyM1VMQkNDOUxXUUlKUFZVUFIxVC4u'

const userManualFile = 'Brukerveiledning_FinnKode.pdf'

const externalUrls = {
	ehelseUrl,
	helsedirUrl,
	ehelseKodeverk,

	/* user manual */
	userManual: userManualFile,

	/* about */
	aboutLab: helsedirKodeverk + aboutLab,
	aboutNorpat: helsedirKodeverk + aboutNorpat,
	aboutIcd: helsedirKodeverk + aboutIcd,
	aboutIcpc: helsedirKodeverk + aboutIcpc,
	aboutPhbu: helsedirKodeverk + aboutPhbu,
	aboutNkpk: helsedirKodeverk + aboutNkpk,
	aboutKis: helsedirKodeverk + aboutKis,
	aboutSnomedct: ehelseKodeverk + aboutSnomedct,

	/* report error */
	reportErrorClinical: koteUrl + kliniskeString,
	reportErrorTerminology: koteUrl + terminologiString,
	reportErrorLab,
	reportErrorEmail,
	reportErrorAdm,
	ehelseFeedbackForm,

	/* temp download file urls */
	downloadExcelIcdTemp: ehelseKodeverk + downloadExcelIcdTemp,
	downloadExcelIcpcTemp: ehelseKodeverk + downloadExcelIcpcTemp,
	downloadExcelNkpkTemp: ehelseKodeverk + downloadExcelNkpkTemp,
	downloadExcelPhbuTemp: ehelseKodeverk + downloadExcelPhbuTemp,
	downloadExcelNorpatTemp: ehelseKodeverk + downloadExcelNorpatTemp,
	downloadCsvNlkTemp: ehelseKodeverk + downloadCsvNlkTemp,

	/* blåbok og kodeveilder */
	blueBookIcd: helsedirKodeverk + blueBookIcdAttachment,
	codingGuideIcd: codingGuideIcdAndNkpk2025Attachment,
	codingGuideNkpk: codingGuideIcdAndNkpk2025Attachment,
	codingGuidePhbu: codingGuidePhbuAttachment,

	snomedctBrowser,
}

export default externalUrls
